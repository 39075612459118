// 角色控制器
import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/auth'
const modules = '/roles'
const path = base + modules

const getRoles = path // 獲取角色列表
const getRolesDetail = path + '/info' // 獲取角色列表信息 (包含座席)
const addRole = path // 添加角色
const editRole = path + '/{0}' // 修改角色
const deleteRole = path + '/{0}?roleId={1}' // 刪除角色

// request(REQ) Function  --------------------------------

/**
 * 獲取角色列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getRolesFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getRoles + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取角色列表信息 (包含座席)
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getRolesDetailFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getRolesDetail + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 添加角色
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
const addRoleFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addRole, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 修改角色
 * @param {String} id 角色id
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
const editRoleFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(editRole, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除角色
 * @param {String} roleId 角色id
 * @param {String} moveRoleId 刪除後轉移角色id
 * @param {Object} callback 回調函數
 * @returns
 */
const deleteRoleFn = (roleId, moveRoleId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(deleteRole, roleId, moveRoleId)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

export {
  getRolesFn,
  getRolesDetailFn,
  addRoleFn,
  editRoleFn,
  deleteRoleFn
}
